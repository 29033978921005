import { Injectable, Renderer2, RendererFactory2 } from "@angular/core";

import { environment } from "@/environments/environment";

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unsafe-call*/
/* eslint-disable prefer-rest-params */

@Injectable({
  providedIn: "root",
})
export class HotjarService {
  private HOTJAR_ID = environment.hotjarId;
  private HOTJAR_VERSION = environment.hotjarVersion;
  private RENDERER_2: Renderer2;

  constructor(rendererFactory: RendererFactory2) {
    this.RENDERER_2 = rendererFactory.createRenderer(null, null);
    if (environment.enableHotjarTracking) {
      this.loadHotjar();
    }
  }

  private loadHotjar(): void {
    const script = this.RENDERER_2.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.src = `https://static.hotjar.com/c/hotjar-${this.HOTJAR_ID}.js?sv=${this.HOTJAR_VERSION}`;
    this.RENDERER_2.appendChild(document.head, script);

    (window as any).hj =
      (window as any).hj ||
      function () {
        ((window as any).hj.q = (window as any).hj.q || []).push(arguments);
      };
    (window as any)._hjSettings = {
      hjid: this.HOTJAR_ID,
      hjsv: this.HOTJAR_VERSION,
    };
  }
}
