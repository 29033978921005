import { HotjarService } from "@core/services/hotjar.service";

import * as WebFont from "webfontloader";

import { ChangeDetectionStrategy, Component } from "@angular/core";

@Component({
  selector: "y-root",
  templateUrl: "./app.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  constructor(private hotjarService: HotjarService) {
    WebFont.load({
      custom: {
        families: [
          "Material Symbols",
          "Material Symbols Outlined",
          "primeicons",
        ],
      },
    });
  }
}
