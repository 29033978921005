import { IDecodedToken } from "@core/interfaces/decoded-token.interface";
import { ILogin } from "@core/interfaces/login.interface";
import { AuthRequest } from "@core/requests/auth.request";
import { UserRequest } from "@core/requests/user.request";
import { TUserFullInformation } from "@core/types/user-full-information.type";

import {
  BehaviorSubject,
  Observable,
  of,
  Subscription,
  switchMap,
  take,
  tap,
} from "rxjs";

import { Nullable } from "primeng/ts-helpers";

import { Injectable, signal, WritableSignal } from "@angular/core";
import { Router } from "@angular/router";

import { TenantService } from "./tenant.service";

@Injectable({
  providedIn: "platform",
})
export class AuthenticationService {
  private decodedTokenSignal: WritableSignal<IDecodedToken | null> =
    signal(null);
  private credential: TUserFullInformation | null = null;
  private credentialsBehaviorSubject!: BehaviorSubject<TUserFullInformation | null>;

  constructor(
    private authRequest: AuthRequest,
    private router: Router,
    private tenantService: TenantService,
    private userService: UserRequest,
  ) {
    this.bootstrapCredentials();
    this.watchCredentialSubject();
  }

  private doUserLogin(
    credential: TUserFullInformation,
    password: string,
  ): void {
    this.credential = credential;
    this.credentialsBehaviorSubject.next(credential);
    localStorage.setItem("authorization", JSON.stringify(credential));

    if (credential.temporaryPassword) {
      this.router
        .navigate(["/login/temporary-password"], {
          state: { oldPassword: password },
        })
        .catch(() => new Error("Failed to redirect"));
      return;
    }

    if (credential.firstAccess) {
      this.router
        .navigate(["/doctor-first-access"])
        .catch(() => new Error("Failed to redirect"));
      return;
    }

    const defaultInstitution = credential.tenants?.find(
      (tenant) => tenant.defaultInstitution,
    );

    if (!defaultInstitution) {
      console.error("Default institution not found");
      this.router
        .navigate(["/app"])
        .catch(() => new Error("Failed to redirect to auth"));
      return;
    }

    this.tenantService.setTenant(defaultInstitution);

    //eslint-disable-next-line
    this.router.navigate(["/app"]).catch((e) => new Error(e));
  }

  private watchCredentialSubject(): Subscription {
    return this.credentialsBehaviorSubject.subscribe((credential) => {
      if (!credential) {
        this.credential = null;
        localStorage.removeItem("authorization");

        return;
      }

      this.credential = credential;

      this.decodedTokenSignal.update(() =>
        this.decodeJwt(credential.accessToken),
      );

      localStorage.setItem("authorization", JSON.stringify(credential));
    });
  }

  private decodeJwt(jwt: string): IDecodedToken {
    return JSON.parse(atob(jwt.split(".")[1])) as IDecodedToken;
  }

  getAuthorization(): TUserFullInformation | null {
    const authorization = localStorage.getItem("authorization");

    return authorization
      ? (JSON.parse(authorization) as TUserFullInformation)
      : null;
  }

  public setCredentialInSubject(
    credentials: TUserFullInformation | null,
  ): void {
    if (this.credentialsBehaviorSubject)
      return this.credentialsBehaviorSubject.next(credentials);

    this.credentialsBehaviorSubject =
      new BehaviorSubject<TUserFullInformation | null>(credentials);
  }

  private bootstrapCredentials(): void {
    const authorization = localStorage.getItem("authorization");

    if (authorization) {
      const credential = JSON.parse(authorization) as TUserFullInformation;

      return this.setCredentialInSubject(credential);
    }

    return this.setCredentialInSubject(null);
  }

  getUserFullInformation(credential: ILogin): Observable<TUserFullInformation> {
    return this.userService.getUserInformation(credential.accessToken).pipe(
      take(1),
      switchMap((fullInformation) => {
        return of({
          ...fullInformation,
          accessToken: credential.accessToken,
          refreshToken: credential.refreshToken,
          expiresIn: credential.expiresIn,
        } as TUserFullInformation);
      }),
    );
  }

  authenticate$(
    username: Nullable<string>,
    password: Nullable<string>,
    token: string,
  ): Observable<TUserFullInformation> {
    return this.authRequest.login(username, password, token).pipe(
      take(1),
      switchMap((credential) => {
        return this.getUserFullInformation(credential);
      }),
      tap((userFullInformation) => {
        this.doUserLogin(userFullInformation, password!);
      }),
    );
  }

  canActivateAuth(): boolean {
    return this.credential !== null;
  }

  logoff(): void {
    this.setCredentialInSubject(null);

    localStorage.clear();
    this.tenantService.setTenant(null);
    this.tenantService.setAvailableTenants(null);

    this.router
      .navigate(["/login"])
      .then(() => {
        window.location.reload();
      })
      .catch(() => new Error("Failed to redirect to login"));
  }

  setCredential(credential: ILogin | TUserFullInformation): void {
    if (!this.credential) {
      throw new Error("Credential not found");
    }

    this.credentialsBehaviorSubject.next({
      ...this.credential,
      accessToken: credential.accessToken,
      refreshToken: credential.refreshToken,
      expiresIn: credential.expiresIn,
    } as TUserFullInformation);
  }

  getCredential(): TUserFullInformation | null {
    return this.credential;
  }

  getDecodedToken(): IDecodedToken | null {
    return this.decodedTokenSignal();
  }

  getCredentialChanges(): Observable<TUserFullInformation | null> {
    return this.credentialsBehaviorSubject.asObservable();
  }
}
