import { Observable, Subject } from "rxjs";

import { Injectable } from "@angular/core";

export interface ISpinnerAction {
  message?: string;
  show: boolean;
}

@Injectable()
export class SpinnerService {
  private spinnerAction$ = new Subject<ISpinnerAction>();

  show(message: string): void {
    this.spinnerAction$.next({
      message,
      show: true,
    });
  }

  hide(): void {
    this.spinnerAction$.next({
      show: false,
    });
  }

  getSpinnerAction$(): Observable<ISpinnerAction> {
    return this.spinnerAction$.asObservable();
  }
}
