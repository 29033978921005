import { MessageService } from "primeng/api";
import { ToastModule } from "primeng/toast";

import { NgModule } from "@angular/core";

import { GlobalToastComponent } from "./global-toast.component";

@NgModule({
  imports: [ToastModule],
  declarations: [GlobalToastComponent],
  providers: [MessageService],
  exports: [GlobalToastComponent],
})
export class GlobalToastModule {}
