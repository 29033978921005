import { TenantService } from "@core/services/tenant.service";

import { Observable, Subject, takeUntil } from "rxjs";

import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";

@Injectable()
export class TenantInterceptor implements HttpInterceptor, OnDestroy {
  destroy$ = new Subject<void>();

  tenant = this.tenantService.getActiveTenant();

  whitelist = ["viacep.com.br"];

  constructor(private tenantService: TenantService) {
    this.tenantService
      .getActiveTenant$()
      .pipe(takeUntil(this.destroy$))
      .subscribe((tenant) => {
        this.tenant = tenant;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const isWhitelisted = this.whitelist.some((url) =>
      request.url.includes(url),
    );

    if (isWhitelisted) return next.handle(request);

    if (!this.tenant) return next.handle(request);

    const modifiedRequest = request.clone({
      setHeaders: {
        tenant: this.tenant?.id,
      },
    });

    return next.handle(modifiedRequest);
  }
}
