import { AuthRequest } from "@core/requests/auth.request";
import { AuthenticationService } from "@core/services/authentication.service";

import { catchError, Observable, switchMap, throwError } from "rxjs";

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable()
export class AutorizationInterceptor implements HttpInterceptor {
  private isRefreshing = false;

  constructor(
    private authenticationService: AuthenticationService,
    private authenticationRequest: AuthRequest,
  ) {}

  whitelist = ["viacep.com.br"];

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const isWhitelisted = this.whitelist.some((url) =>
      request.url.includes(url),
    );

    if (isWhitelisted) return next.handle(request);

    const credential = this.authenticationService.getCredential();

    if (!credential?.accessToken) return next.handle(request);

    const interceptedRequest = this.setTokenInRequest(
      credential.accessToken,
      request,
    );

    return next.handle(interceptedRequest).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status !== HttpStatusCode.Unauthorized) {
            return throwError(() => error);
          }

          return this.refreshToken(request, next);
        }

        return throwError(() => error);
      }),
    );
  }

  setTokenInRequest(
    token: string,
    request: HttpRequest<unknown>,
  ): HttpRequest<unknown> {
    return request.clone({
      setHeaders: { authorization: `Bearer ${token}` },
    });
  }

  refreshToken(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (this.isRefreshing) return next.handle(request);

    this.isRefreshing = true;

    const refreshToken =
      this.authenticationService.getCredential()?.refreshToken;

    if (!refreshToken) return next.handle(request);

    return this.authenticationRequest.refreshToken(refreshToken).pipe(
      switchMap((credential) => {
        this.isRefreshing = false;

        this.authenticationService.setCredential(credential);

        const interceptedRequest = this.setTokenInRequest(
          credential.accessToken,
          request,
        );

        return next.handle(interceptedRequest);
      }),
      catchError((error: HttpErrorResponse) => {
        this.isRefreshing = false;
        if (error.status !== 401) return throwError(() => error);

        this.authenticationService.logoff();

        return throwError(() => error);
      }),
    );
  }
}
