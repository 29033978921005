import { IKeyValidator } from "@core/interfaces/key-validator.interface";

export const keyValidatorConstant: IKeyValidator[] = [
  {
    key: "maxlength",
    message: "Tamanho máximo de caracteres atingido",
  },
  {
    key: "required",
    message: "O campo é obrigatório",
  },
  {
    key: "email",
    message: "Você precisa colocar no formato de e-mail",
  },
  {
    key: "passwordMatch",
    message: "As duas senhas não coincidem",
  },
  {
    key: "cpfNotValid",
    message: "O CPF não é válido",
  },
  {
    key: "max",
    message: "Você pode colocar até $param caracteres",
  },
  {
    key: "mask",
    message: "Você precisa preencher totalmente o campo",
  },
  {
    key: "onlyLetters",
    message: "Só é possível incluir letras",
  },
  {
    key: "fullName",
    message: "Seu nome deve estar completo",
  },
];
