import { IRequestOptions } from "@core/interfaces/request-option.interface";

import { Observable } from "rxjs";

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from "@/environments/environment";

/* eslint @typescript-eslint/ban-types: 0 */
/* eslint @typescript-eslint/no-explicit-any: 0 */

export function applicationHttpClientCreator(
  http: HttpClient,
): ApplicationHttpClient {
  return new ApplicationHttpClient(http);
}

@Injectable()
export class ApplicationHttpClient {
  private api = environment.ymunityApiUrl;

  public constructor(public client: HttpClient) {}

  /**
   * GET request
   * @param {string} endPoint it doesn't need / in front of the end point
   * @param {IRequestOptions} options options of the request like headers, body, etc.
   * @returns {Observable<T>}
   */
  public get<T>(endPoint: string, options?: IRequestOptions): Observable<T> {
    return this.client.get<T>(this.api + endPoint, options);
  }

  /**
   * POST request
   * @param {string} endPoint end point of the api
   * @param {Object} params body of the request.
   * @param {IRequestOptions} options options of the request like headers, body, etc.
   * @returns {Observable<T>}
   */
  public post<T>(
    endPoint: string,
    params?: Object,
    options?: IRequestOptions,
  ): Observable<T> {
    return this.client.post<T>(this.api + endPoint, params, options);
  }

  /**
   * PUT request
   * @param {string} endPoint end point of the api
   * @param {Object} params body of the request.
   * @param {IRequestOptions} options options of the request like headers, body, etc.
   * @returns {Observable<T>}
   */
  public put<T>(
    endPoint: string,
    params?: Object,
    options?: IRequestOptions,
  ): Observable<T> {
    return this.client.put<T>(this.api + endPoint, params, options);
  }

  /**
   * DELETE request
   * @param {string} endPoint end point of the api
   * @param {IRequestOptions} options options of the request like headers, body, etc.
   * @returns {Observable<T>}
   */
  public delete<T>(endPoint: string, options?: IRequestOptions): Observable<T> {
    return this.client.delete<T>(this.api + endPoint, options);
  }
}
