import { ProgressSpinnerModule } from "primeng/progressspinner";

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { SpinnerComponent } from "./spinner.component";

@NgModule({
  imports: [ProgressSpinnerModule, CommonModule],
  declarations: [SpinnerComponent],
  exports: [SpinnerComponent],
})
export class SpinnerModule {}
