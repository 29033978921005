import { Directive, ElementRef, Input, Renderer2 } from "@angular/core";

@Directive({
  selector: "label[appAutoConfigure]",
})
export class AutoConfigureLabelDirective {
  @Input() useRequiredValidator = true;
  @Input() icon?: string;
  @Input() configureLabel = true;
  constructor(
    private el: ElementRef<HTMLLabelElement>,
    private renderer: Renderer2,
  ) {
    this.configureLabelElement();
  }

  configureLabelElement(): void {
    if (!this.configureLabel) return;
    this.renderer.setStyle(this.el.nativeElement, "display", "block");
    this.renderer.setStyle(this.el.nativeElement, "margin-left", "10px");
    this.renderer.setStyle(this.el.nativeElement, "padding-bottom", "4px");
    this.renderer.setStyle(this.el.nativeElement, "line-height", "18px");
  }

  setRequired(): void {
    const asteriskSpan = this.renderer.createElement("span") as HTMLSpanElement;

    const asteriskText = this.renderer.createText("*") as HTMLElement;
    this.renderer.appendChild(asteriskSpan, asteriskText);
    this.renderer.addClass(asteriskSpan, "p-error");
    this.renderer.appendChild(this.el.nativeElement, asteriskSpan);
  }
}
