import { AbstractControl, FormArray, FormGroup } from "@angular/forms";

export class FormUtil {
  static markControlAsTouchedAndDirty(control: AbstractControl): void {
    if (control instanceof FormGroup) {
      this.markFormGroupControlsTouchedAndDirty(control);
      return;
    }

    if (control instanceof FormArray) {
      this.markFormArrayControlsTouchedAndDirty(control);
    }

    control.markAsTouched();
    control.markAsDirty();
  }

  static markFormArrayControlsTouchedAndDirty(formArray: FormArray): void {
    formArray.controls.forEach((control) => {
      if (!(control instanceof AbstractControl)) {
        this.markFormGroupControlsTouchedAndDirty(control);

        return;
      }

      this.markGenericFormAsTouchedAndDirty(control);
    });
  }

  static markGenericFormAsTouchedAndDirty(
    control: AbstractControl | FormGroup | FormArray,
  ): void {
    this.markControlAsTouchedAndDirty(control);
  }

  static markFormGroupControlsTouchedAndDirty(formGroup: FormGroup): void {
    Object.values(formGroup.controls).forEach((control) => {
      if (control instanceof FormGroup) {
        this.markFormGroupControlsTouchedAndDirty(control);
        return;
      }

      if (control instanceof FormArray) {
        this.markFormArrayControlsTouchedAndDirty(control);
        return;
      }

      this.markGenericFormAsTouchedAndDirty(control);
    });
  }

  static hasFormTouched(form: FormGroup | FormArray): boolean {
    if (form instanceof FormGroup || form instanceof FormArray) {
      if (form.dirty) {
        return form.dirty;
      }

      const formControls = Object.values(form.controls);

      for (const control of formControls) {
        if (control instanceof FormGroup || control instanceof FormArray) {
          return this.hasFormTouched(control);
        }

        if (control.dirty) {
          return control.dirty;
        }
      }
    }

    return false;
  }

  static setAsPristine(form: FormGroup | FormArray): void {
    if (form instanceof FormGroup || form instanceof FormArray) {
      if (form.dirty) {
        return form.markAsPristine({ onlySelf: true });
      }

      const formControls = Object.values(form.controls);

      for (const control of formControls) {
        if (control instanceof FormGroup || control instanceof FormArray) {
          return this.setAsPristine(control);
        }

        if (control.dirty) {
          return form.markAsPristine({ onlySelf: true });
        }
      }
    }
  }

  static canContinueOrShowError(formGroup: FormGroup): boolean {
    if (formGroup.valid) return true;
    this.markFormGroupControlsTouchedAndDirty(formGroup);
    return false;
  }
}
