import { keyValidatorConstant } from "@core/constants/key-validator.constant";
import { IKeyValidator } from "@core/interfaces/key-validator.interface";

import { Subscription, debounceTime } from "rxjs";

import {
  AfterViewInit,
  ContentChild,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  Optional,
  Self,
  Signal,
  signal,
} from "@angular/core";
import { AbstractControl, NgControl } from "@angular/forms";

import { AutoConfigureLabelDirective } from "./auto-configure-label.directive";

@Directive({
  selector: "[appAutoValidator]",
})
export class AutoValidatorDirective implements AfterViewInit, OnDestroy {
  private errorClasses = ["ng-invalid", "ng-dirty"];
  private element: HTMLElement;
  private subscription?: Subscription;
  private errors = signal<string[]>([]);
  private control!: NgControl;

  @ContentChild(NgControl, { descendants: true }) descendantControl!: NgControl;

  @ContentChild(AutoConfigureLabelDirective, { descendants: true })
  autoConfigureLabelDirective?: AutoConfigureLabelDirective;

  @Input() useKeyValueErrors = false;
  @Input() keyValidators: IKeyValidator[] = keyValidatorConstant;

  constructor(
    @Optional() @Self() private injectedControl: NgControl,
    private elementRef: ElementRef,
  ) {
    this.element = this.elementRef.nativeElement as HTMLElement;
  }

  ngAfterViewInit(): void {
    this.handleGlobalControl();
    this.subscription = this.control.valueChanges
      ?.pipe(debounceTime(200))
      .subscribe(() => {
        this.autoValidate();
      });
  }

  handleGlobalControl(): void {
    if (this.injectedControl) {
      this.control = this.injectedControl;
      return;
    }
    this.control = this.descendantControl;

    this.markAsRequiredByValidator();
  }

  markAsRequiredByValidator(): void {
    if (!this.autoConfigureLabelDirective) {
      return;
    }

    if (!this.autoConfigureLabelDirective.useRequiredValidator) {
      return;
    }

    if (!this.control.control?.validator) return;

    const validator = this.control.control.validator({} as AbstractControl);

    if (!validator || !validator["required"]) {
      return;
    }

    this.autoConfigureLabelDirective.setRequired();
  }

  getErrors(): Signal<string[]> {
    return this.errors.asReadonly();
  }

  private addErrorClasses(): void {
    this.errorClasses.forEach((key) => this.element.classList.add(key));
  }

  private removeErrorClasses(): void {
    this.errorClasses.forEach((key) => this.element.classList.remove(key));
  }

  //eslint-disable-next-line
  private addKeyValueErrors(keyValue: [string, any][]): void {
    this.errors.update(() => keyValue.map(([key]) => key));
  }

  private hasPrerequisites(): boolean | null {
    return this.control.dirty && this.control.touched;
  }

  private addKeyErrors(keys: string[]): void {
    const keyValidators = this.keyValidators.filter((validator) =>
      keys.some((key) => key === validator.key),
    );

    this.errors.update(() => keyValidators.map((key) => key.message));
  }

  autoValidate(): void {
    if (!this.hasPrerequisites()) return;

    const errors = this.control.errors;

    if (!errors) {
      this.errors.update(() => []);
      return this.removeErrorClasses();
    }

    this.addErrorClasses();

    const errorEntries = Object.entries(errors);

    if (this.useKeyValueErrors) {
      return this.addKeyValueErrors(errorEntries);
    }

    return this.addKeyErrors(errorEntries.map(([key]) => key));
  }

  ngOnDestroy(): void {
    if (!this.subscription) return;

    this.subscription.unsubscribe();
  }
}
