import {
  AfterViewInit,
  ContentChildren,
  Directive,
  EventEmitter,
  Output,
  QueryList,
} from "@angular/core";

import { FormValidatorDirective } from "./form-validator.directive";

@Directive({ selector: "[appFormArrayValidator]" })
export class FormArrayValidatorDirective implements AfterViewInit {
  @ContentChildren(FormValidatorDirective, { descendants: true })
  formValidatorDirective?: QueryList<FormValidatorDirective>;

  @Output() afterDirectiveInit = new EventEmitter<this>();

  validateAndContinue(cb: (err: boolean) => void): void {
    let hasError = false;

    this.formValidatorDirective?.forEach((directive) => {
      directive.validateAndContinue((err) => {
        if (err) {
          hasError = true;
        }
      });
    });

    return cb(hasError);
  }

  ngAfterViewInit(): void {
    this.afterDirectiveInit.emit(this);
  }
}
