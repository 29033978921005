import { ITenant } from "@core/interfaces/tenant.interface";

import { BehaviorSubject, Observable } from "rxjs";

import { Injectable } from "@angular/core";

@Injectable()
export class TenantService {
  private activeTenant$ = new BehaviorSubject<ITenant | null>(null);
  private availableTenants$ = new BehaviorSubject<ITenant[] | null>(null);

  private activeTenant: ITenant | null = null;

  setTenant(tenant: ITenant | null): void {
    localStorage.setItem("active-tenant", JSON.stringify(tenant));

    this.activeTenant = tenant;

    const lastTenant = this.activeTenant$.getValue();

    this.activeTenant$.next(tenant);

    const availableTenants = this.availableTenants$.getValue();

    if (availableTenants === null) return;

    if (tenant === null) return;

    if (lastTenant === null) return;

    this.setAvailableTenants(
      [...availableTenants, lastTenant].filter((t) => t.id !== tenant.id),
    );
  }

  setAvailableTenants(tenants: ITenant[] | null): void {
    localStorage.setItem("available-tenants", JSON.stringify(tenants));

    this.availableTenants$.next(tenants);
  }

  bootstrapTenant(): void {
    const activeTenant = localStorage.getItem("active-tenant");
    if (!activeTenant) {
      this.activeTenant = null;
      return this.activeTenant$.next(null);
    }

    this.setTenant(JSON.parse(activeTenant) as ITenant);
  }
  bootstrapAvailableTenants(): void {
    const localStorageTenants = localStorage.getItem("available-tenants");
    if (!localStorageTenants) return this.availableTenants$.next(null);

    const availableTenants = JSON.parse(localStorageTenants) as ITenant[];

    this.setAvailableTenants(availableTenants);
  }

  getActiveTenant$(): Observable<ITenant | null> {
    this.bootstrapTenant();
    return this.activeTenant$.asObservable();
  }

  getActiveTenant(): ITenant | null {
    return this.activeTenant;
  }

  getAvailableTenants$(): Observable<ITenant[] | null> {
    this.bootstrapAvailableTenants();
    return this.availableTenants$.asObservable();
  }
}
