import { IQuestion } from "@core/interfaces/question.interface";

import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "radioAnswer",
})
export class RadioAnswerPipe implements PipeTransform {
  transform(value: IQuestion): string {
    if (!value.answer) {
      return "Não respondido";
    }

    const answerId = Array.isArray(value.answer)
      ? (value.answer[0] as string)
      : (value.answer as string);

    const currentOption = value.options?.find(
      (option) => option.id === answerId,
    );

    if (!currentOption) throw new Error("Current option not found");

    return currentOption.label;
  }
}
