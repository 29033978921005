import { IStateService } from "@core/interfaces/state-service.interface";

import { BehaviorSubject, Observable } from "rxjs";

export class ABehaviorSubjectState<T> implements IStateService<T> {
  state$: BehaviorSubject<T | null> = new BehaviorSubject<T | null>(null);

  update(state: T | null): Observable<T | null> {
    this.state$.next(state);

    return this.state$.asObservable();
  }

  get$(): Observable<T | null> {
    return this.state$.asObservable();
  }
}
