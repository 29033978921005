import { BehaviorSubject, Subject } from "rxjs";

import { Injectable } from "@angular/core";

@Injectable()
export class SidebarService {
  useCustomDesktopSidebar$ = new BehaviorSubject<boolean>(false);

  mobileSidebarToggleClick$ = new Subject<void>();
}
