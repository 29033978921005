import { SpinnerService } from "@core/services/spinner.service";

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  OnInit,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: "y-spinner",
  templateUrl: "./spinner.component.html",
  styleUrls: ["./spinner.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerComponent implements OnInit {
  show = false;
  message = "";

  constructor(
    private spinnerService: SpinnerService,
    private destroyRef: DestroyRef,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.spinnerService
      .getSpinnerAction$()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((action) => {
        if (!action.show) {
          this.show = false;
          this.message = "Carregando...";
          this.cdr.detectChanges();
          return;
        }

        this.show = true;
        this.message = action.message ?? "Carregando...";
        this.cdr.detectChanges();
      });
  }
}
