import { ApplicationHttpClient } from "@core/config/application-http-client/application-http-client";
import { IExperienceEvaluation } from "@core/interfaces/experience-evaluation.interface";
import { IUserInformation } from "@core/interfaces/user-information.interface";
import { IUser } from "@core/interfaces/user.interface";

import { Observable } from "rxjs";

import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "any",
})
export class UserRequest {
  constructor(private httpClient: ApplicationHttpClient) {}

  getById(userId: string): Observable<IUser> {
    return this.httpClient.get<IUser>(`/v1/users/${userId}`);
  }

  deleteAvatarByUserId(userId: string): Observable<void> {
    return this.httpClient.delete<void>(`/v1/users/${userId}/avatar`);
  }

  addUserAvatar(userId: string, formData: FormData): Observable<void> {
    return this.httpClient.post<void>(`/v1/users/${userId}/avatar`, formData);
  }

  getUserInformation(authorization?: string): Observable<IUserInformation> {
    return this.httpClient.get<IUserInformation>(
      "/v2/users/me",
      authorization
        ? {
            headers: new HttpHeaders().append(
              "Authorization",
              `Bearer ${authorization}`,
            ),
          }
        : {},
    );
  }

  postUserEvaluationAskMeLater(): Observable<void> {
    return this.httpClient.post<void>(
      "/v2/users/experience-evaluation/ask-me-later",
    );
  }

  postUserEvaluationExperience(
    experienceEvaluation: IExperienceEvaluation,
  ): Observable<void> {
    return this.httpClient.post<void>(
      "/v2/users/experience-evaluation",
      experienceEvaluation,
    );
  }
}
