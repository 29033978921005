import { AfterViewInit, Directive, ElementRef } from "@angular/core";

/*eslint-disable*/

@Directive({
  selector: "[appFixNgxMask]",
})
export class FixNgxMaskDirective implements AfterViewInit {
  constructor(public element: ElementRef<HTMLElement>) {
  }

  ngAfterViewInit(): void {
    this.element.nativeElement.focus()
    setTimeout(() => {
      this.element.nativeElement.blur()
    }, 100)
  }
}
