import { AutoValidatorDirective } from "@modules/shared/directives/auto-validator.directive";

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  effect,
  Injector,
  signal,
  Signal,
} from "@angular/core";

@Component({
  selector: "y-container",
  templateUrl: "./container.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContainerComponent implements AfterViewInit {
  @ContentChild(AutoValidatorDirective)
  directive?: AutoValidatorDirective;

  errors: Signal<string[]> = signal([]).asReadonly();

  currentMessage = signal("");

  constructor(private injector: Injector) {}

  ngAfterViewInit(): void {
    if (!this.directive) return;
    this.errors = this.directive.getErrors();

    effect(
      () => {
        if (!this.errors().length) {
          return this.currentMessage.update(() => "");
        }

        this.currentMessage.update(() => this.errors()[0]);
      },
      { injector: this.injector, allowSignalWrites: true },
    );
  }
}
