import { ContainerComponent } from "@core/components/container/container.component";
import { FormUtil } from "@core/util/form.util";

import {
  AfterViewInit,
  ContentChildren,
  Directive,
  QueryList,
} from "@angular/core";
import { FormGroup, FormGroupDirective } from "@angular/forms";

import { AutoValidatorDirective } from "./auto-validator.directive";

@Directive({ selector: "[appFormValidator]" })
export class FormValidatorDirective implements AfterViewInit {
  @ContentChildren(ContainerComponent, { descendants: true })
  containers?: QueryList<ContainerComponent>;

  @ContentChildren(AutoValidatorDirective, { descendants: true })
  autoValidatorDirective?: QueryList<AutoValidatorDirective>;

  private formGroup?: FormGroup;

  constructor(private formGroupDirective: FormGroupDirective) {}

  isFormValid(): boolean {
    if (!this.formGroup) throw new Error("FormGroup not found");

    this.forceErrorsInAutoValidate();

    return this.formGroup.valid;
  }

  forceErrorsInAutoValidate(formGroup?: FormGroup): void {
    const internalFormGroup = formGroup ?? this.formGroup;

    if (!internalFormGroup) throw new Error("FormGroup not found");

    FormUtil.markFormGroupControlsTouchedAndDirty(internalFormGroup);

    this.forceAutoValidate();
  }

  forceAutoValidate(): void {
    if (!this.autoValidatorDirective)
      throw new Error("AutoValitadorDirectives not found!");

    this.autoValidatorDirective.forEach((directive) =>
      directive.autoValidate(),
    );
  }

  validateAndContinue(cb?: (err: boolean) => void): void {
    if (!this.formGroup) throw new Error("FormGroup not found!");

    if (this.formGroup.valid) {
      if (cb) cb(false);
      return;
    }

    const formGroupControl: FormGroup[] | null = this.getFormGroup(
      this.formGroup,
    );
    if (formGroupControl)
      formGroupControl.forEach((control: FormGroup) =>
        FormUtil.markFormGroupControlsTouchedAndDirty(control),
      );

    FormUtil.markFormGroupControlsTouchedAndDirty(this.formGroup);

    this.forceAutoValidate();

    if (cb) cb(true);
    return;
  }

  private getFormGroup(formGroup: FormGroup): FormGroup[] | null {
    const controlList: FormGroup[] = [];

    for (const controlName in formGroup.controls) {
      const control = formGroup.controls[controlName];
      if (control instanceof FormGroup) controlList.push(control);
    }

    return controlList.length > 0 ? controlList : null;
  }

  ngAfterViewInit(): void {
    this.formGroup = this.formGroupDirective.form;
  }
}
