import {
  ApplicationHttpClient,
  applicationHttpClientCreator,
} from "@core/config/application-http-client/application-http-client";

import { HttpClient, HttpClientModule } from "@angular/common/http";
import { NgModule, Provider } from "@angular/core";

const customProviders: Provider[] = [
  {
    provide: ApplicationHttpClient,
    useFactory: applicationHttpClientCreator,
    deps: [HttpClient],
  },
];

@NgModule({
  providers: [...customProviders],
  imports: [HttpClientModule],
  exports: [HttpClientModule],
})
export class AplicationHttpClientModule {}
