import { PrimeNGTranslationConstant } from "@core/constants/primeng-translation.constant";

import { PrimeNGConfig } from "primeng/api";

import { APP_INITIALIZER, NgModule, Provider } from "@angular/core";

function initializeTranslation(config: PrimeNGConfig): () => void {
  return (): void => config.setTranslation(PrimeNGTranslationConstant);
}

const translationProvider: Provider = {
  provide: APP_INITIALIZER,
  useFactory: initializeTranslation,
  deps: [PrimeNGConfig],
  multi: true,
};

@NgModule({
  providers: [translationProvider],
})
export class TranslationModule {}
