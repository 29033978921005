import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "textQuestion",
})
export class TextQuestionPipe implements PipeTransform {
  transform(value: unknown): string {
    const stringValue = value as string;

    if (!stringValue) return "Não respondido";

    return stringValue;
  }
}
