import { ApplicationHttpClient } from "@core/config/application-http-client/application-http-client";
import { ILogin } from "@core/interfaces/login.interface";
import {
  IChangePassword,
  IResetPassword,
} from "@core/interfaces/reset-password.interface";

import { Observable } from "rxjs";

import { Nullable } from "primeng/ts-helpers";

import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable()
export class AuthRequest {
  constructor(private httpClient: ApplicationHttpClient) {}

  login(
    email: Nullable<string>,
    password: Nullable<string>,
    token: string,
  ): Observable<ILogin> {
    return this.httpClient.post<ILogin>("/v2/authenticate/login", {
      email,
      password,
      token,
    });
  }

  recoveryAccount(email: string): Observable<void> {
    return this.httpClient.post("/v1/authenticate/forgot-password", { email });
  }

  refreshToken(token: string): Observable<ILogin> {
    return this.httpClient.post(
      "/v1/authenticate/refresh-token",
      {},
      { params: new HttpParams().append("refreshTokenId", token) },
    );
  }

  newPassword(password: string): Observable<void> {
    return this.httpClient.post("/v1/authenticate/new-password", {
      password,
      confirmPassword: password,
    });
  }

  changePassword(passwordForm: IChangePassword): Observable<void> {
    return this.httpClient.post("/v1/authenticate/change-password", {
      ...passwordForm,
    });
  }

  resetPassword(passwordForm: IResetPassword): Observable<void> {
    return this.httpClient.post("/v1/authenticate/reset-password", {
      ...passwordForm,
    });
  }
}
