import { Injectable } from "@angular/core";

import { AuthenticationService } from "./authentication.service";

@Injectable()
export class AuthorizationService {
  constructor(private authenticationService: AuthenticationService) {}

  hasPermission(permission: string[]): boolean {
    const decodedToken = this.authenticationService.getCredential();

    if (!decodedToken) {
      throw new Error("Decoded token not found");
    }

    const claims = decodedToken.roleClaims
      .map((roleClaim) => roleClaim.claims)
      .flat();

    return claims.some((p) => permission.includes(p));
  }
}
